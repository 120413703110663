import HTTP from '@/http-common'
export default {

    getWhatsappAll() { //eslint-disable-line
        let request = [
            `/v1/whatsapp/get`
        ]
        return HTTP.get(...request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error.response))
    },
    whatsappDelete(id) {
        HTTP.setHeader(true)
        let request = [
            `/v1/whatsapp/delete/${id}`
        ]
        return new Promise((s, r) => {
            HTTP.delete(...request).then(s, r).then(() => { return }, () => { return })
        })
    }
}