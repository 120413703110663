<template lang="pug">
    whatsap-index
</template>

<script>
import Whatsapp from "@/components/whatsapp/Index.vue"
import { validatePermission } from "@/Ability"

const COMPONENT_NAME = "ViewIndexWhatsapp";

export default {
  name: COMPONENT_NAME,
  components: {
    "whatsap-index": Whatsapp,
  },
  created() {
    validatePermission(this, "read", "whatsapp", "Home");
  },
};
</script>