<template>
  <div id="div-with-loading-status" class="vs-con-loading__container" v-if="$can('read', 'whatsapp')">
    <ag-grid-table
      :animateRows="true"
      :suppressRowClickSelection="true"
      :columnDefs="chartConfig.columnDefs"
      :rowData="chartConfig.rowData"
      :gridOptions="gridOptions"
      :sizeTofit="true"
      rowSelection="multiple"
      @RefreshTableData="RefreshTableData"
    >
      <template v-if="isActionBar()" v-slot:actionBar>
        <ag-action-bar
          :is_add="$can('create', 'whatsapp')"
          :is_edit="$can('update', 'whatsapp')"
          :is_activate="false"
          :is_deactivate="false"
          :is_exportable="false"
          :is_import="false"
          @add="modalCreateWhatsapp"
          @edit="onBtnEdit"
        ></ag-action-bar>
      </template>
    </ag-grid-table>
    <vue-form
      ref="FormWhatsapp"
      :popupActivo="popupActivo"
      :whatsapp="currentWhatsapp"
      @refreshTable="getWhatsappAll"
      @closePopup="closePo"
    ></vue-form>
  </div>
</template>
<script>

import { validatePermission } from "@/Ability"
import { mapState } from "vuex"
import whatsappApi from "@/api/whatsapp/Whatsapp"
import WhatsappForm from "@/components/whatsapp/Form"
import AgGridTable from "@/components/tableAgGrid/Index"
import ActionsBar from "@/components/tableAgGrid/ActionsBar"
import FieldsWhatsapp from "@/fields/whatsapp/fieldsWhatsapp"
import { translateTableFields } from "@/filter"

const NAME = "WhatsappIndex";

export default {
  name: NAME,
  components: {
    "vue-form": WhatsappForm,
    "ag-grid-table": AgGridTable,
    "ag-action-bar": ActionsBar,
  },
  data() {
    return {
      popupActivo: false,
      popupActivoUpdate: false,
      currentWhatsapp: null,
      gridOptions: {
        localeText: { noRowsToShow: `${this.$t("show_not_data")}` },
      },
      chartConfig: {
        columnDefs: translateTableFields(FieldsWhatsapp.columnDefs, 'whatsapp', {
          activate: {
            valueGetter: this.setActivate
          }
        }),
        rowData: []
      }
    }
  },
  computed: {
    ...mapState("whatsapp", ["whatsappEdit", "whatsappDelete"]),
  },
  watch: {
    whatsappEdit(val) {
      if (val != null) {
        this.popupActivo = !this.popupActivo
        this.currentWhatsapp = this.whatsappEdit
      }
    },
    whatsappDelete(val) {
      if (val != null) {
        this.onBtnTrashInline(val)
      }
    },
  },
  methods: {
    setActivate (val) {
      if (val.data.activate) {
        return this.$t("whatsapp.activate")
      } else {
        return this.$t("whatsapp.deactivate")
      }
    },
    isActionBar() {
      if (
        this.$can("read", "whatsapp") ||
        this.$can("create", "whatsapp") ||
        this.$can("update", "whatsapp") ||
        this.$can("delete", "whatsapp")
      ) {
        return true
      } else {
        return false
      }
    },
    RefreshTableData() {
      this.asignColumnDefs();
    },
    asignColumnDefs() {
      this.chartConfig.columnDefs = [];
      setTimeout(() => {
        this.chartConfig.columnDefs = translateTableFields(FieldsWhatsapp.columnDefs, 'whatsapp', {
          activate: {
            valueGetter: this.setActivate
          }
        })
      }, 50)
    },
    onBtnTrashInline(data) {
      this.$swal({
        position: "center",
        type: "info",
        title: this.$t("whatsapp.delete_whatsapp"),
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        showCancelButton: true,
        showConfirmButton: true,
      }).then((result) => {
        if (result.value) {
          if (data.id == null) {
            this.$vs.notify({
              text: this.$t("whatsapp.nonRemovablewhatsapp"),
              color: "danger",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            if (data.id) {
              whatsappApi
                .whatsappDelete(data.id)
                .then(() => {
                  this.getWhatsappAll()
                })
                .catch(() => {
                  this.$vs.notify({
                    text: this.$t("whatsapp.nonRemovablewhatsapp"),
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                  })
                })
            }
          }
        } else {
          this.$store.dispatch("whatsapp/set_delete_whatsapp", null)
        }
      });
    },
    onBtnEdit() {
      let data = this.gridOptions.api.getSelectedNodes()
      if (data.length > 0) {
        if (data[0].data.id == null) {
          this.$vs.notify({
            text: this.$t("whatsapp.nonEditablewhatsapp"),
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        } else {
          this.popupActivo = true;
          this.currentWhatsapp = data[0].data;
        }
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    onBtnTrash() {
      let data = this.gridOptions.api.getSelectedNodes().map((x) => {
        return x.data.id_sms;
      });
      if (data.length > 0) {
        this.showDeleteAlert((res) => {
          if (res.value == true) {
            let smsData = {
              sms: data,
            };
            whatsappApi
              .smsDeleteMultiple(smsData)
              .then(() => {
                this.getSmsAll();
              })
              .catch(() => {
                this.$vs.notify({
                  text: this.$t("whatsapp.nonRemovablewhatsapp"),
                  color: "danger",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              });
          }
        });
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    showDeleteAlert(callback) {
      this.$swal({
        position: "center",
        type: "warning",
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        title: this.$t("whatsapp.delete_whatsapp"),
        showCancelButton: true,
        showConfirmButton: true,
      }).then(callback);
    },
    closePo(val) {
      if (val != null) {
        this.$refs.FormWhatsapp.clear()
      }
      this.popupActivo = false
      this.currentWhatsapp = null
    },
    modalCreateWhatsapp() {
      this.popupActivo = true;
      this.currentWhatsapp = null;
    },
    getWhatsappAll() {
      this.$vs.loading({
        container: "#div-with-loading-status",
        type: "material",
        scale: 1,
      });
      whatsappApi
        .getWhatsappAll()
        .then((response) => {
          this.chartConfig.rowData = response.data;
          // setTimeout(() => {
          //   let allColumnIds = []
          //   this.gridOptions.columnApi.getAllColumns().forEach(column => {
          //     allColumnIds.push(column.colId)
          //   })
          //   this.gridOptions.columnApi.autoSizeColumns(allColumnIds)
          // }, 50)
        })
        .finally(() => {
          this.$vs.loading.close("#div-with-loading-status > .con-vs-loading");
        });
    },
  },
  mounted() {
    this.getWhatsappAll();
  },
  created() {
    validatePermission(this, "read", "whatsapp", "Home");
  },
};
</script>