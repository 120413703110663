<template>
  <vs-popup
    id="popupStatusForm"
    classContent="popup-example"
    :title="$t('status.form')"
    :active.sync="popup"
  >
    <div class="vx-row">
      <div class="vx-col w-full mb-2 sm:w-1/1">
        <label class="vs-select--label">{{ $t('users.company') }}</label>
        <v-select
          :options="companies"
          label="name"
          :clearable="false"
          name="company"
          :data-vv-as="$t('users.company')"
          v-validate="'required'"
          v-model="company"
          :placeholder="$t('users.company')"
          autocomplete="off"
        >
          <template slot="option" slot-scope="option">{{ option.name | capitalize }}</template>
        </v-select>
        <span
          class="text-danger text-sm"
          v-show="errors.has('company')"
        >{{ errors.first('company') }}</span>
      </div>
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <vs-divider></vs-divider>
        <vs-list>
          <vs-list-item :title="$t('sms.estado')">
            <vs-switch v-model="activate"
              ><span slot="on">{{ $t("dynamic_fields.yes") }}</span
              ><span slot="off">{{ $t("dynamic_fields.not") }}</span></vs-switch
            >
          </vs-list-item>
        </vs-list>
      </div>
    </div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
        <vs-button
          class="mt-6 block offset-3"
          color="primary"
          type="filled"
          @click="setSave"
        >{{ $t('devices.save') }}</vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import HTTP from "@/http-common"
import ErrorNotify from "@/components/errors/Errors"
import i18n from "@/i18n.js"

export default {
  props: {
    whatsapp: {
      type: Object,
      required: false,
    },
    popupActivo: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      i18n: i18n,
      company: null,
      company_id: null,
      companies: [],
      activate: false,
      status_id: null,
      id_whatsapp: null
    }
  },
  computed: {
    popup: {
      get() {
        return this.popupActivo;
      },
      set(val) {
        if (!val) {
          this.$emit("closePopup", this.whatsapp);
          this.$store.dispatch("whatsapp/set_whatsapp", null);
        }
      },
    }
  },
  watch: {
    company(val) {
      if (val != null) {
        this.company_id = this.company.id
      }
    },
    whatsapp(val) {
      this.clear()
      if (val != null) {
        this.company_id = val.company_id
        this.activate = val.activate
        this.id_whatsapp = val.id
        if (this.company_id) {
          for (let i=0; i < this.companies.length; i++) {
            if (this.company_id == this.companies[i].id) {
              this.company = this.companies[i]
            }
          }
        }
      }
    },
  },
  methods: {
    getCompany() {
      HTTP.get("/v1/companies/companies/").then((response) => {
        this.companies = response.data.map((row) => ({
          id: row.id,
          name: row.company,
          version: row.version
        }))
      });
    },
    clear() {
      this.company = null;
      this.company_id = null;
      this.$validator.reset()
    },
    setSave() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let dataFormated = {
            activate: this.activate,
            company_id: this.company.id
          }
          if (this.whatsapp == null) {
            this.createWhatsapp(dataFormated)
          } else {
            this.updateWhatsapp(dataFormated)
          }
        }
      });
    },
    updateWhatsapp(dataFormated) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let url = `v1/whatsapp/update/${this.id_whatsapp}`
          HTTP.setHeader(true)
          HTTP.put(url, dataFormated)
            .then(() => {
              this.$vs.notify({
                text: this.$t("whatsap.update"),
                color: "#64cc52",
                iconPack: "feather",
                icon: "icon-check-circle"
              });
              this.clear()
              this.popup = false
              this.$emit("refreshTable")
            })
            .catch((response) => {
              ErrorNotify.formsErrors({
                data: response.response,
                ob: this
              })
            })
        }
      })
    },
    createWhatsapp(dataFormated) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let url = "/v1/whatsapp/create"
          HTTP.setHeader(true)
          HTTP.post(url, dataFormated)
            .then(() => {
              this.$vs.notify({
                text: this.$t("whatsapp.create"),
                color: "#64cc52",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.popup = false;
              this.clear();
              this.$emit("refreshTable")
            })
            .catch((response) => {
              ErrorNotify.formsErrors({
                data: response.response,
                ob: this
              })
            })
        }
      });
    },
  },
  created() {
    if (!this.companies.length) this.getCompany()
  },
  beforeDestroy() {
    var elem = document.querySelector("#popupStatusForm")
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
  }
}
</script>

<style>
.toggle-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
